import { ref, onMounted } from 'vue'
import { industryListApi, getDictData } from '@/services/index.js'
import { arrayToMap } from "@/utils/common"

export function useDict (type) {
  const dictList = ref([])
  const dictMap = ref({})
  const getDictList = async (type) => {
    try {
      const res = await getDictData({ dictType: type })
      dictList.value = res.data?.map(item => ({
        value: item.dictValue,
        label: item.dictLabel
      })) || []
      dictMap.value = arrayToMap(dictList.value, 'value', 'label')
    } catch (error) {
      console.error(error)
      dictList.value = []
    }
  }
  onMounted(() => { getDictList(type) })
  return {
    dictList,
    dictMap,
    getDictList
  }
}

export function useIndustryList(lang) {
  const industryList = ref([])
  const industryMap = ref({})
  try {
    if (lang == 'zh') {
      industryListApi()
      .then(res => {
        industryList.value = res.data?.map(item => ({
          value: item.code,
          label: item.alias
        })) || []
        industryMap.value = arrayToMap(industryList.value, 'value', 'label')
      })
      .catch()
    } else {
      getDictData({ dictType: 'industry_tags' }).then(res => {
        industryList.value = res.data?.map(item => ({
          value: item.dictValue,
          label: item.dictLabel
        })) || []
        industryMap.value = arrayToMap(industryList.value, 'value', 'label')
      })
    }
  } catch (error) {
    console.error(error)
  }
  return {
    industryList,
    industryMap
  }
}

export function useCompanyType () {
  const companyTypeList = ref([])
  const companyTypeMap = ref({})
  const getCompanyTypeList = async () => {
    try {
      const res = await getDictData({ dictType: 'company_type' })
      companyTypeList.value = res.data?.map(item => ({
        value: item.dictValue,
        label: item.dictLabel
      })) || []
      companyTypeMap.value = arrayToMap(companyTypeList.value, 'value', 'label')
    } catch (error) {
      console.error(error)
      dictList.value = []
    }
  }
  onMounted(() => { getCompanyTypeList() })
  return {
    companyTypeList,
    companyTypeMap,
    getCompanyTypeList
  }
}

