import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/animate.css'
import './assets/css/swiper.min.css'
import './assets/css/common.css'
import './assets/css/main.css'
import './assets/css/share.min.css'


import $ from 'jquery'
import { wechatInit } from './utils/wechat'

import './assets/js/common.js'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import { store } from './store';
import i18n from './locale/index'

import { getUrlLang } from '@/utils/common'
window.routerPush = (path) => {
	let lang = getUrlLang(location.href)
	const p = lang ? `/${lang}${path}` : path
	console.log('window.routerPush', p)
  router.push(p)
}
wechatInit()

createApp(App).use(i18n).use(router).use(store).use(ElementPlus, { locale: zhCn }).use($).mount('#app')

if (process.env.NODE_ENV !== 'development') { // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
	console.log = function () {}
	console.error = function(){}
	console.dir = function(){}
	console.warn = () => {};
}

