import CLRequest from '../request'


// jaka+
export function getJakaList(params) {
  return CLRequest.get({ 
    url: "/api/jaka+/allList",
    params
  })
}

// jaka+系列详情
export function getJakaListDetail(id,status) {
  return CLRequest.get({ 
    url: "/api/jaka+/getProductListById/" + id + '/' + status,
  })
}
// jaka+ 具体详情
export function getJakaAddDetail(id,status) {
  return CLRequest.get({ 
    url: `/api/jaka+/getProductDetails/${id}/${status}`,
  })
}