import CLRequest from '../request'


// 产品下拉菜单
export function ProductList_xl(status) {
  return CLRequest.get({ 
    url: "/api/productCenter/productLinePullDown/" + status,
    
  })
}
export function selectProductLinePublishDataById(id) {
  return CLRequest.get({ 
    url: "/api/productCenter/selectPublishDataById/" + id,
    
  })
}
// 产品系列列表
export function ProductLists(params) {
    return CLRequest.get({ 
      url: "/api/productCenter/productLineList",
      params
    })
}
// 产品系列详情
export function ProductListsDetail(id,status) {
    return CLRequest.get({ 
      url: `/api/productCenter/getProductLineDetails/${id}/${status}` ,
    })
}
// 产品详情
export function ProductDetail(id,status,flag) {
    return CLRequest.get({ 
      url: `/api/productCenter/getProductDetails/${id}/${status}/${flag}` ,
    })
}
// 产品对比
export function getProductContrast(ids) {
    return CLRequest.get({ 
      url: `/api/productCenter/productContrast/`+ids ,
    })
}