import CLRequest from '../request'


export function aboutLocation(params) {
  return CLRequest.get({ 
    url: "/api/aboutJaka/contactUsList",
    params
  })
}
export function aboutHistory(status) {
  return CLRequest.get({ 
    url: "/api/aboutJaka/developingCourseList/" + status,
  })
}

export function recruitPosition(data) {
  return CLRequest.post({
    url: "/api/recruit/position/list",
    data
  })
}

export function recruitPositionConfig(data) {
  return CLRequest.post({
    url: "/api/recruit/positionConfig/list",
    data
  })
}
