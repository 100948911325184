 
import { createApp } from 'vue' 
import { ElMessage, ElLoading } from 'element-plus'
import sessionCache from '@/utils/cache'
import {
  tokenValidateApi,
} from "@/services/index.js";

import { createStore } from 'vuex' 
const store = createStore({ // 在这里定义您的state，mutations，actions等 
  state: {
		count:0
  },
  mutations: {
  },
  actions: {
		loginValidate(ctx ,token){
			return new Promise((resolve,reject) => {
				tokenValidateApi(token).then(res => {
					console.log(res);
					if(res?.success){
						resolve(res)
					}else{
						ElMessage({
							message: '登录失效',
							offset: 200,
						})
						console.log('用户不存在正在清除数据');
						sessionCache.deleteCache("jakaUserId");
						sessionCache.deleteCache("jakaUserName");
						sessionCache.deleteCache("jakaUserAccount");
						sessionCache.deleteCache("jakaUserInfo");

						resolve(res)
					}
				})
			})
		},
		increment(context) {
		//类似提交service层
			context.commit('increment');
		}
  },
  //类似计算属性
  getters: {
  }
}) 
 

 
export default store