import './city.js'
let city = provinceList

export function fixCity () {
	for (let i in city) {
		city[i].value = i
		for (let x in city[i].children) {
			city[i].children[x].value = {
					city:city[i].children[x].label,
					province: city[i].label,
			}
			delete city[i].children[x].children
		}
	}
	return city
}