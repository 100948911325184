
import { reactive, ref } from 'vue'
import i18n from '@/locale/index'
const { t } = i18n.global

// 表单验证
import { checkPhoneOrEmailForm, checkPhoneForm, checkNumberForm, checkEmailForm, checkPhoneOrCodeForm } from '@/utils/formValidate.js'

export const isValidateEvent = ref(false)

export const loginRules = reactive({
  phoneOrEmail: [{
    required: true,
    message: t('form.validate.phoneEmailNull'),
    trigger: 'blur',
  }, {
    validator: checkPhoneOrEmailForm,
    message: t('form.validate.phoneEmailFormat'),
    trigger: 'blur',
  }],
  email: [{
    required: true,
    message: t('form.validate.emailNull'),
    trigger: 'blur',
  }, {
    validator: checkEmailForm,
    message: t('form.validate.emailFormat'),
    trigger: 'blur',
  }],
  code: [{
    required: true,
    message: t('form.validate.captchaNull'),
    trigger: 'blur',
  },
  { type: 'number', message: t('form.validate.captchaNumber') },],
  password: [{
    required: true,
    message: t('form.validate.passwordNull'),
    trigger: 'blur',
  },],
})

export const forgetRules = reactive({
  phoneOrEmail: [{
    required: true,
    message: t('form.validate.phoneEmailNull'),
    trigger: 'blur',
  }, {
    validator: checkPhoneOrEmailForm,
    message: t('form.validate.phoneEmailFormat'),
    trigger: 'blur',
  }],
  email: [{
    required: true,
    message: t('form.validate.emailNull'),
    trigger: 'blur',
  }, {
    validator: checkEmailForm,
    message: t('form.validate.emailFormat'),
    trigger: 'blur',
  }],
  code: [{
    required: true,
    message: t('form.validate.captchaNull'),
    trigger: 'blur',
  },
  { type: 'number', message: t('form.validate.captchaNumber') },],
  password: [{
    required: true,
    message: t('form.validate.passwordNull'),
    trigger: 'blur',
  },],
  confirmPassword: [{
    required: true,
    message: t('form.validate.password2Null'),
    trigger: 'blur',
  },],
})


export const registerRules = reactive({
  phoneOrEmail: [{
    required: true,
    message: t('form.validate.phoneEmailNull'),
    trigger: 'blur',
  }, {
    validator: checkPhoneOrEmailForm,
    message: t('form.validate.phoneEmailFormat'),
    trigger: 'blur',
  }],
  email: [{
    required: true,
    message: t('form.validate.emailNull'),
    trigger: 'blur',
  }, {
    validator: checkEmailForm,
    message: t('form.validate.emailFormat'),
    trigger: 'blur',
  }],
  code: [{
    required: true,
    message: t('form.validate.captchaNull'),
    trigger: 'blur',
  },
    // { type: 'number', message: t('form.validate.captchaNumber') },
  ],
  password: [{
    required: true,
    message: t('form.validate.passwordNull'),
    trigger: 'blur',
  }],
  confirmPassword: [{
    required: true,
    message: t('form.validate.password2Null'),
    trigger: 'blur',
  }],
})

export const registerPasswordRules = reactive({
  phoneOrEmail: [{
    required: true,
    message: t('form.validate.phoneEmailNull'),
    trigger: 'blur',
  }, {
    validator: checkPhoneOrEmailForm,
    message: t('form.validate.phoneEmailFormat'),
    trigger: 'blur',
  }],
  email: [{
    required: true,
    message: t('form.validate.emailNull'),
    trigger: 'blur',
  }, {
    validator: checkEmailForm,
    message: t('form.validate.emailFormat'),
    trigger: 'blur',
  }],
  code: [{
    required: true,
    message: t('form.validate.captchaNull'),
    trigger: 'blur',
  },
    // { type: 'number', message: t('form.validate.captchaNumber') },
  ],
  password: [{
    required: true,
    message: t('form.validate.passwordNull'),
    trigger: 'blur',
  }],
  confirmPassword: [{
    required: true,
    message: t('form.validate.password2Null'),
    trigger: 'blur',
  }],
})

export const userInfoRules = reactive({
  userName: [{
    required: true,
    message: t('form.validate.userNameNull'),
    trigger: 'blur',
  }],
  companyName: [{
    required: true,
    message: t('form.validate.userNameNull'),
    trigger: 'blur',
  }],
  industry: [{
    required: true,
    message: t('form.validate.industryNull'),
    trigger: 'change',
  }],
  phoneNumber: [{
    required: true,
    message: t('form.validate.mobileNull'),
    trigger: 'blur',
  }, {
    validator: checkNumberForm,
    message: t('form.validate.mobileFormat'),
    trigger: 'blur',
  }],
  code: [{
    required: true,
    message: t('form.validate.captchaNull'),
    trigger: 'blur',
  },
    // { type: 'number', message: t('form.validate.captchaNumber') },
  ],
  phoneCode: [{
    required: true,
    message: t('form.validate.captchaNull'),
    trigger: 'blur',
  },
    // { type: 'number', message: t('form.validate.captchaNumber') },
  ],
  email: [{
    required: true,
    message: t('form.validate.emailNull'),
    trigger: 'blur',
  }, {
    validator: checkEmailForm,
    message: t('form.validate.emailFormat'),
    trigger: 'blur',
  }],
  phoneOrEmail: [{
    required: true,
    message: t('form.validate.emailNull'),
    trigger: 'blur',
  }, {
    validator: checkEmailForm,
    message: t('form.validate.emailFormat'),
    trigger: 'blur',
  }],
  password: [{
    required: true,
    message: t('form.validate.passwordNull'),
    trigger: 'blur',
  }],
  confirmPassword: [{
    required: true,
    message: t('form.validate.password2Null'),
    trigger: 'blur',
  }],
})



// 供应商
export const contactRules = reactive({
  userName: [{
    required: true,
    message: t('form.validate.userNameNull'),
    trigger: 'blur',
  }],
  phoneNumber: [{
    required: true,
    message: t('form.validate.telephoneNull'),
    trigger: 'blur',
  }, {
    validator: checkNumberForm,
    message: t('form.validate.telephoneFormat'),
    trigger: 'blur',
  }],
  email: [{
    required: true,
    message: t('form.validate.emailNull'),
    trigger: 'blur',
  }, {
    validator: checkEmailForm,
    message: t('form.validate.emailFormat'),
    trigger: 'blur',
  }],
  city: [{
    required: true,
    message: t('form.validate.cityNull'),
    trigger: 'blur',
  }],
  communicateMatter: [{
    required: true,
    message: t('form.validate.communicateMatterNull'),
    trigger: 'blur',
  }],
  specificRequirements: [{
    required: true,
    message: t('form.validate.coRequirementsNull'),
    trigger: 'blur',
  }],
  companyName: [{
    required: true,
    message: t('form.validate.companyNameNull'),
    trigger: 'blur',
  }],
  industry: [{
    required: true,
    message: t('form.validate.industryNull'),
    trigger: 'blur',
  }],
  companyType: [{
    required: true,
    message: t('form.validate.companyType'),
    trigger: 'blur',
  }],
  comment: [{
    required: true,
    message: t('form.validate.comment'),
    trigger: 'blur',
  }],
})


export const certRules = reactive({
  phoneOrCode: [{
    required: true,
    message: t('form.validate.phoneOrCodeNull'),
    trigger: 'blur',
  }, {
    validator: checkPhoneOrCodeForm,
    message: t('form.validate.phoneOrCodeFormat'),
    trigger: 'blur',
  },],
})