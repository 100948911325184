import { createI18n } from 'vue-i18n'
import * as messages from './lang/index'
import sessionCache from "@/utils/cache";
import { getUrlLang } from '@/utils/common'

let defaultLang = getUrlLang(location.href) || 'zh'
if (defaultLang == 'jp') {
  defaultLang = 'ja'
}
sessionCache.setCache('lang', defaultLang)

const i18n = createI18n({
  legacy: false,
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages,
})

export function setLang(locale) {
  i18n.global.locale.value = locale
  sessionCache.setCache('lang', locale)
}

export default i18n
