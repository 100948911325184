import axios from 'axios'
import { BASE_URL, TIMEOUT,baseAPI } from './config'
// 浏览器存储
import sessionCache from '@/utils/cache'
import { ElMessage, ElLoading } from 'element-plus'

class CLRequest {
  constructor(baseURL, timeout=10000) {
    this.instance = axios.create({
      baseURL,
      timeout
    })
    // 请求拦截器
    this.instance.interceptors.request.use(config => {
      // console.log('请求拦截');
      // console.log(config);
      
      // // 携带token
      const jakaUserInfo = sessionCache.getCache('jakaUserInfo')
      const lang = sessionCache.getCache('lang')
      config.headers.languagesCode = `${lang || 'zh'}`
			if (jakaUserInfo) {
				// config.headers.Authorization = `${token}`
        console.log(jakaUserInfo.token)
				config.headers['token'] = `${jakaUserInfo.token}`
			}

      return config

    }, err => {
      // console.log("请求出错，错误信息如下：",err);
      return err
    })
    // 响应拦截器
    this.instance.interceptors.response.use(res => {
      // console.log('响应拦截');
      return res
    }, err => {
      // console.log("请求出错，错误信息如下：",err);
      return err
    })
  }
  request(config) {
    return new Promise((resolve, reject) => {
      this.instance.request(config).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(config) {
    return this.request({ ...config, method: "get" })
  }

  post(config) {
    return this.request({ ...config, method: "post" })
  }
}

export default new CLRequest( process.env.VUE_APP_BASE_API, TIMEOUT)
// export default new CLRequest(process.env.VUE_APP_BASE_URL + process.env.VUE_APP_BASE_API, TIMEOUT)


