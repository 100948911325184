import CLRequest from '../request'

// 手机号验证码获取
export function getPhoneCodeApi(data) {
  return CLRequest.post({ 
    url: "/login/phoneVerificationCode",
    data
  })
}
// 邮箱验证码获取
export function getEmailCodeApi(data) {
  return CLRequest.post({ 
    url: "/login/emailVerificationCode",
    data
  })
}
// 登陆
// 手机验证码登陆
export function phoneCodeLoginApi(data) {
  return CLRequest.post({ 
    url: "/login/phoneCodeLogin",
    data
  })
}
// 手机密码登陆
export function phonePasswordLoginApi(data) {
  return CLRequest.post({ 
    url: "/login/phonePasswordLogin",
    data
  })
}
// 邮箱验证码登陆
export function emailCodeLoginApi(data) {
  return CLRequest.post({ 
    url: "/login/emailCodeLogin",
    data
  })
}
// 邮箱密码登陆
export function emailPasswordLoginApi(data) {
  return CLRequest.post({ 
    url: "/login/emailPasswordLogin",
    data
  })
}

// 注册
// 手机注册
export function phoneRegisterApi(data) {
  return CLRequest.post({ 
    url: "/login/phoneRegister",
    data
  })
}
// 邮箱注册
export function emailRegisterApi(data) {
  return CLRequest.post({ 
    url: "/login/emailRegister",
    data
  })
}
// 海外邮箱注册
export function overseasEmailRegisterApi(data) {
  return CLRequest.post({ 
    url: "/login/overseasEmailRegister",
    data
  })
}
// 行业下拉列表
export function industryListApi(data) {
  return CLRequest.post({ 
    url: "/login/industryList",
    data
  })
}
// 手机忘记密码
export function phoneForgetApi(data) {
  return CLRequest.post({ 
    url: "/login/phoneResetPass",
    data
  })
}
// 邮箱忘记密码
export function emailForgetApi(data) {
  return CLRequest.post({ 
    url: "/login/emailResetPass",
    data
  })
}

// 获取微信二维码
export function getWxEwm(data) {
  return CLRequest.post({ 
    url: "/login/wxCode",
    data
  })
}

// 获取微信二维码
export function getWxCodeCallback(data) {
  return CLRequest.post({ 
    url: "/login/wxCodeCallback",
    data
  })
}
// 获取微信二维码
export function findUserInfo(trackId) {
  return CLRequest.get({ 
    url: "/login/findUserInfo/" + trackId, 
    
  })
}

// 注销账号
export function deregisterApi(data) {
  return CLRequest.post({ 
    url: "/login/logOff", 
    data
  })
}


// token校验
export function tokenValidateApi(token) {
  return CLRequest.post({ 
    url: "/login/validateToken?token=" + token, 
    
  })
}
// // 注销校验
export function deregisterValidateApi(data) {
  return CLRequest.post({ 
    url: "/login/inspectUserIsExit", 
    data
  })
}