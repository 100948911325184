
import { ElMessage, ElLoading } from 'element-plus'
import sessionCache from '@/utils/cache'
import { useRoute } from 'vue-router'

import {
  tokenValidateApi,
} from "@/services/index.js";

export function loginValidate(token){

	return new Promise((resolve,reject) => {
		tokenValidateApi(token).then(res => {
			if(res?.success){
				resolve(res)
			}else{
				ElMessage({
					message: '登录失效',
					offset: 200,
				})
				console.log('用户不存在正在清除数据');
				sessionCache.deleteCache("jakaUserId");
				sessionCache.deleteCache("jakaUserName");
				sessionCache.deleteCache("jakaUserAccount");
				sessionCache.deleteCache("jakaUserInfo");

				// location.reload()
				resolve(res)
			}
		})
	})
	
	
}

export function checkLogin() {
  const route = useRoute()
  let userInfo = {}
  userInfo = sessionCache.getCache("jakaUserInfo") || null
  if(route.name == 'virtual' && route.query.token ){
	userInfo = {token: route.query.token}
  }
  return new Promise((resolve,reject) => {
		if(userInfo){
			loginValidate(userInfo.token).then(res => {
				if(res.success) {
						resolve(true)
				} else {
					reject(false)
				}
			})
		} else{
			reject(false)
		}
  })
}