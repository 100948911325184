import CLRequest from '../request'


// crm通用
export function crmCommApi(data) {
  return CLRequest.post({ 
    url: "/crm/addSaleClue",
    data
  })
}

// crm通用
export function crmOverseasCommApi(data) {
  return CLRequest.post({
    url: "/crm/addOverseasSaleClue",
    data
  })
}


// 证书查询
export function certQueryApi(params) {
  return CLRequest.get({ 
    url: "/crm/getExamResultByCodeOrPhone",
    params
  })
}
// 获取证书
export function getCertImgApi(data) {
  return CLRequest.post({ 
    url: "/crm/generateImage",
    data
  })
}

// 经销商查询



export function getProvider(customerName) {
  return CLRequest.get({ 
    url: `/api/service/validateDealer/` + customerName,
    
  })
}

// 城市查询
export function checkCity(region) {
  return CLRequest.get({ 
    url: `/api/service/selectCustomerRegion?region=`+region
    
  })
}

