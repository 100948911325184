export const isWechat = () => {
  let ua = window.navigator.userAgent
  if (/miniprogram/i.test(ua)) {
    return true
  }
  return false
}

export const wechatInit = () => {
  if (isWechat()) {
    var script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js"
    document.getElementsByTagName("head")[0].appendChild(script)

    script.onload = () => {
      // 需要初始化sdk环境，等等...
    }
  }
}