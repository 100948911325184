
const numberReg = /^\d+$/
const phoneReg = /^1[3-9][0-9]{9}$/
const emailReg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/

export function checkPhoneOrEmailForm(rule, value, callback){
	if(!phoneReg.test(value) && !emailReg.test(value)){
		callback(new Error())
	}else{
		callback()
	}
}
export function checkPhoneForm(rule, value, callback){
	if(!phoneReg.test(value)){
		callback(new Error())
	}else{
		callback()
	}
}
export function checkNumberForm(rule, value, callback){
	if(!numberReg.test(value)){
		callback(new Error())
	}else{
		callback()
	}
}
export function checkEmailForm(rule, value, callback){
	if(!emailReg.test(value)){
		callback(new Error())
	}else{
		callback()
	}
}

export function checkPhoneOrCodeForm(rule, value, callback){
	if( value.length == 11){
		if(!phoneReg.test(value)){
			callback(new Error())
		}else{
			callback()
		}
	}else{
		callback()
	}
}
