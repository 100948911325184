// // export const BASE_URL = "http://192.168.8.158:8888/"
// const currentPort = window.location.port;
// export const BASE_URL = "http://118.31.121.77:" + currentPort

// // export const BASE_URL = "http://121.41.72.228:8080/"


export const TIMEOUT = 10000 





