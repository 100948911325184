

import CLRequest from '../request'


// 首页模型卡片
export function getModelCards(status) {
	return CLRequest.get({ 
		url: `/api/home/cardModelList/` + status,
	})
}


// 首页行业
export function getHomeRecommendIndustry(status) {
	return CLRequest.get({ 
		url: `/api/home/recommendIndustry/` + status,
	})
}