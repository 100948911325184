  
    

        $(function () {
            //锚点跳转滑动效果  
            $('a.ph').click(function () {
                $('a.ph').removeClass("on")
                $(this).addClass("on")
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                    var $target = $(this.hash);
                    // console.log($target.offset().top)
                    $target = $target.length && $target || $('[id=' + this.hash.slice(1) + ']');
                    if ($target.length) {
                        var targetOffset = $target.offset().top;
                        $('html,body').animate({
                            scrollTop: targetOffset + 0+"px"
        
                        },
                            500);
                        return false;
                    }
                }
            });
            
        });	
$(function(){


        var a,b,c;
        a=$(window).height();
        $(window).scroll(function(){
            var b=$(this).scrollTop();
            $(".dh").each(function(){
            c=$(this).offset().top;
            if(a+b>c){
                $(this).addClass("show")
            }
            else{
            $(this).removeClass("show");
            }
            });
        });
        
        var p=0;t=0;
        var htt;
        var hb = $(".ny_banner").height()
        
        $(window).scroll(function(e){
          p=$(this).scrollTop();
          var w = $(window).width()
              if(t<=p && p > 10){
                //   console.log('下滚')
                $(".header").addClass("on1")
                $(".m_header").addClass("on")
              }
              else if(t>p && p == 0){
                //   console.log('上滚')
                // $(".header").removeClass("on1")
                $(".m_header").removeClass("on")
              }
              if(p-hb>0){
                  $(".pl_con .ml").addClass("show")
              }
              else if(p-hb<0){
                  $(".pl_con .ml").removeClass("show")
              }
          
          t = p;
        })
        
        $(window).scroll(function(e){
          p=$(this).scrollTop();
          var h = $("body").height()
          var h1 = $(window).height()
          // console.log(h-p)
          var hp = h-p
          if(p>200){
              $(".right_box").addClass("on")
          }else{
              $(".right_box").removeClass("on")
          }
          if(hp==h1){
              $(".right_box").removeClass("on")
          }if(p>500){
              $(".i_part6").removeClass("hide")
          }else{
              $(".i_part6").addClass("hide")
          }
        })
    
        
        // pc头部
        $("header .center>li").hover(function(){
            // console.log("456465")
            $(this).find("ul").stop().slideDown()
        },function(){
            $(this).find("ul").stop().slideUp()
        })
        
        $(".header .w1680 .right .lag").hover(function(){
            // console.log("456465")
            $(this).find("ul").stop().slideDown()
        },function(){
            $(this).find("ul").stop().slideUp()
        })
        
        
        $(".footer .f_top .left>div .link").hover(function(){
            // console.log("456465")
            $(this).find("ul").stop().slideDown()
        },function(){
            $(this).find("ul").stop().slideUp()
        })
        
        /*手机头部*/
        $(".mh_nav .close").click(function(){
            $(".mh_nav").removeClass("show")
        })
        $(".m_header .m_btn").click(function(){
            
            if($(this).hasClass("on")){
                $(this).removeClass("on")
                $(".mh_nav").stop().slideUp()
                
            }else{
                $(this).addClass("on")
                $(".mh_nav").stop().slideDown()
            }
        })
        
        // 回到顶部
        
        $(".back").click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 500);
            return false;
        });
    })
    
//     $(function () {
//     var w = $(window).width();
//     if (w > 1200) {
//         if (!(/msie [6|7|8|9]/i.test(navigator.userAgent))) {
//             new WOW(
//                 {
//                     callback: function (box) {
//                         $(box).addClass("wow1");
//                     }
//                 }).init();

//         }

//         $(window).resize(function () {

//             new WOW(
//                 {
//                     callback: function (box) {
//                         $(box).addClass("wow1");
//                     }
//                 }).init();

//         });
//     }

            
// }) 