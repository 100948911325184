import CLRequest from '../request'


// 
export function getJakaAcademyLesson(params) {
  return CLRequest.get({ 
    url: "/api/service/curriculumsList",
    params
  })
}
// 
export function getJakaAcademyVideo(params) {
  return CLRequest.get({ 
    url: "/api/service/courseList",
    params
  })
}

// 
export function getJakaAcademyDict(id) {
  return CLRequest.get({ 
    url: "/api/service/courseTypeList/" + id,
  })
}
export function getJakaAcademyInstructor(params) {
  return CLRequest.get({ 
    url: "/api/service/instructorList",
    params
  })
}
