// const baseURL = process.env.VUE_APP_BASE_URL
const baseAPI = process.env.VUE_APP_BASE_API
// const currentPort = window.location.port;
const currentPort = '80'

export default function formatImg(pathSpace){
	let khLPath = pathSpace?.replaceAll('(', '%28')
	let khRPath = khLPath?.replaceAll(')', '%29')
	let path = khRPath?.replaceAll(' ', '%20')
	
	// console.log('path',baseAPI + path);
	return  baseAPI + path
}

export  function formatUrlToLine(pathSpace){
	return  pathSpace?.replaceAll(' ', '_')
}

export  function formatUrlToSpace(pathSpace){
	return  pathSpace?.replaceAll('_', ' ')
}