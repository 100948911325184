import CLRequest from '../request'


export function solutionXl(status) {
  return CLRequest.get({ 
    url: "/api/solution/solutionPullDown/" + status,
  })
}
// 行业推荐
export function industryRecommend() {
    return CLRequest.get({ 
      url: `/api/solution/Industry/recommendIndustry`,
    })

}
// 行业详情
export function industryDetail(id,status) {
    return CLRequest.get({ 
      url: `/api/solution/getIndustryDetails/${id}/${status}`,
    })
}
// 产品级应用详情
export function productLevelDetail(id,status) {
    return CLRequest.get({ 
      url: `/api/solution/getProductApplyDetails/${id}/${status}` ,
    })
}
// 应用详情
export function applicationDetail(id,status) {
    return CLRequest.get({ 
      url: `/api/solution/getApplyDetails/${id}/${status}` ,
    })
}
// 案例列表
export function getCaseList(params) {
    return CLRequest.get({ 
      url: `/api/solution/applicationsList` ,
      params
    })
}
// 案例详情
export function getCaseDetail(id,status) {
    return CLRequest.get({ 
      url: `/api/solution/getApplicationsDetails/${id}/${status}` ,
    })
}
// 案例列表
export function getCaseProductList(id,status) {
    return CLRequest.get({ 
      url: `/api/productCenter/getProductById/` + id + '/' + status,
    })
}